<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Títulos',
              quantity: data.counters.master_titles.total,
              icon: 'flag-checkered',
              change: data.counters.master_titles.actives,
              changeVar: 'títulos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entregados',
              quantity: data.counters.titles.total,
              icon: 'trophy',
              change: data.counters.titles.actives,
              changeVar: 'títulos activos'
            }"
            bgColor="bg-blue-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <div class="my-auto mx-2">
            <searchbar
              :disabled="parameters.selector != 1"
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.selector"
              :options="[{ id: 1, name: 'Títulos' },{ id: 2, name: 'Entregados' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.type"
              :options="[{ id: 1, name: 'Academia' },{ id: 2, name: 'Entrenador' },{ id: 3, name: 'Jugador' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group v-if="parameters.selector == 2" class="mx-2">
            <t-select
              v-model="parameters.year"
              :options="[{id: 2021, name: '2021'},{id: 2022, name: '2022'},{id: 2023, name: '2023'},{id: 2024, name: '2024'}]"
              placeholder="Año"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          v-if="parameters.selector == 1"
          variant="warning"
          class="my-2 md:my-auto mr-2 h-fit py-2"
          @click="$router.push('/titulos/crear')"
        >
          Crear título
        </t-button>
        <t-button
          v-else
          variant="warning"
          class="my-2 md:my-auto mr-2 h-fit py-2"
          @click="$router.push('/titulos/entregados/crear')"
        >
          Asignar título
        </t-button>
      </div>

      <div v-if="parameters.selector == 1" class="w-full">
        <basic-table
          ref="list-table"
          module="title"
          :headers="headers"
          :url="'titulos'"
          :data="titles"
          :route="'/title'"
          :tableName="'TÍTULOS'"
          :parameters="parameters"
          :isMaster="true"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
      <div v-else class="w-full">
        <basic-table
          ref="list-table2"
          module="otherTitle"
          :headers="headers2"
          :url="'titulos/entregados'"
          :data="titles2"
          :route="'/title/type/'+parameters.type"
          :isMaster="true"
          :tableName="'ENTREGADOS'"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: ["", "Nombre", "Tipo", "Registrado el", "Acciones"],
      headers2: ["", "Título", "Galardonado", "Año", "Tipo", "Acciones"],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {},
          master_titles: {}
        }
      },
      query: "",
      parameters: {
        //order_key: this.selector == 1 ? 'id' : 'year',
        //order_value: 'desc',
        selector: 1,
        type: 1,
        year: null,
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    titles() {
      return this.$store.getters["global/getItems"]("title", "elements").map(
        (el) => ({
          id: el.id,
          image: el.url_picture,
          name: el.name,
          type: el.type == 1 ? 'Academia' : (el.type == 2 ? 'Entrenador' : 'Jugador'),
          datetime: el.created_at,
          active: el.active
        })
      )
    },
    titles2() {
      return this.$store.getters["global/getItems"]("otherTitle", "elements").map(
        (el) => {
          if (this.parameters.type == 1) {
            return {
              id: el.id,
              image: el.title ? el.title.url_picture : "",
              title: el.title ? el.title.name : "",
              name: el.academy ? el.academy.name : "",
              year: el.year,
              type: "Academia",
              active: el.active,
            }
          }
          else if (this.parameters.type == 2) {
            return {
              id: el.id,
              image: el.coach ? el.coach.url_profile_picture : "",
              title: el.title ? el.title.name : "",
              name: el.coach ? el.coach.first_name + " " + el.coach.last_name : "",
              year: el.year,
              type: "Entrenador",
              active: el.active,
            }
          }
          else {
            return {
              id: el.id,
              image: el.player ? el.player.url_profile_picture : "",
              title: el.title ? el.title.name : "",
              name: el.player ? el.player.first_name + " " + el.player.last_name : "",
              year: el.year,
              type: "Jugador",
              active: el.active,
            }
          }
        }
      )
    },
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"] && this.parameters.selector == 1)  this.$refs["list-table"].changeQuery(value)
      //if (this.$refs["list-table2"] && this.parameters.selector == 2) this.$refs["list-table2"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      if (this.parameters.selector == 1)   this.data.counters.master_titles.actives--
      else                      this.data.counters.titles.actives--
    },
    activate() {
      if (this.parameters.selector == 1)   this.data.counters.master_titles.actives++
      else                      this.data.counters.titles.actives++
    },
    erase() {
      if (this.parameters.selector == 1) {
        this.data.counters.master_titles.total--
        this.data.counters.master_titles.actives--
      }
      else {
        this.data.counters.titles.total--
        this.data.counters.titles.actives--
      }
    },
  },
  created() {
    this.getCounters()
  }
}
</script>
